/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { matchPath, Routes, useLocation, useNavigate } from 'react-router-dom';

import store from '@core/store/redux';
import DefaultLayout from '@layout/index';
import authenticationPresenter from '@modules/authentication/presenter';
import { removeProfile, TokenSelector } from '@modules/authentication/profileStore';
import { useSingleAsync } from '@shared/hook/useAsync';

import { privateRouter } from '../index';
import ShowRouter from './ShowRouter';

const PrivatePage: React.FC = () => {
  const token = useSelector(TokenSelector);
  const getProfile = useSingleAsync(authenticationPresenter.getProfile);
  const location = useLocation();
  const route = ShowRouter({ routers: privateRouter, privateRoute: true });
  const navigate = useNavigate();

  useEffect(() => {
    const checkRemember = (token, rememberMe) => {
      if (token !== '' && rememberMe === '1') {
        getProfile.execute();
      } else {
        store.dispatch(removeProfile());
        navigate('/login');
      }
    };

    const bc = new BroadcastChannel('hca_channel');
    const checkedRememberInTab = new Promise((resolve, reject) => {
      bc.onmessage = event => {
        if (event.data === 'loaded' && window.sessionStorage.getItem('remember-me')) {
          bc.postMessage(JSON.stringify({ remember: '1' }));
        } else if (JSON.parse(event.data).remember === '1') {
          window.sessionStorage.setItem('remember-me', '1');
          resolve('1');
        } else {
          resolve(undefined);
        }
      };
      if (window.sessionStorage.getItem('remember-me')) {
        bc.postMessage(JSON.stringify({ remember: '1' }));
      } else {
        bc.postMessage('loaded');
      }
      setTimeout(() => {
        resolve(undefined);
      }, 100);
    });

    checkedRememberInTab.then(res => {
      const remember =
        window.sessionStorage.getItem('remember-me') ||
        window.localStorage.getItem('remember-me') ||
        res;

      checkRemember(token, remember);
    });

    return () => {
      bc.close();
    };
  }, [token]);

  const showMasterLayout = React.useMemo(() => {
    const r = route.routes.find(it => it.path && matchPath(it.path, location.pathname));
    return r?.masterLayout !== false;
  }, [location.pathname, route.routes]);

  const showwidthFull = React.useMemo(() => {
    const result = route?.routes?.find(it => it.path && matchPath(it.path, location.pathname));
    return result?.widthFull;
  }, [location.pathname, route.routes]);

  return (
    <DefaultLayout
      loading={getProfile.status !== 'ready'}
      hideHeader={!showMasterLayout}
      hideSideBar={!showMasterLayout}
      widthFull={showwidthFull}
    >
      <Routes>{route.views}</Routes>
    </DefaultLayout>
  );
};
export default React.memo(PrivatePage);
