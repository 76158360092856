/* eslint-disable @typescript-eslint/no-non-null-assertion */
import Loading from '@shared/components/Loading';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { HashRouter as Router } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import store, { persistor } from 'src/core/store/redux';

import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root')!);
root.render(
  <Router>
    <Provider store={store}>
      <PersistGate loading={<Loading/>} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  </Router>,
);
