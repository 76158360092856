export default {
  'organizations.create': 'Thêm Tổ chức',
  'organizations.code': 'Mã Tổ chức',
  'organizations.name': 'Tên Tổ chức',
  'organizations.description': 'Mô tả',
  'organizations.update': 'Chỉnh sửa Tổ chức',
  'organizations.information': 'Thông tin Tổ chức',
  'organizations.createdAt': 'Thời gian tạo',
  'organizations.action': 'Hành động',
  'organizations.confirm.title.delete': 'Bạn có muốn xóa những Tổ chức này ?',
  'organizations.confirm.content.delete': 'Bạn vui lòng xác nhận lại',
};
