import React from 'react';

import { IRouter } from '@routers/interface';

export const routerForgot: IRouter = {
  path: '/forgot',
  loader: React.lazy(() => import('./index')),
  exact: true,
  masterLayout: false,
};
export const routerForgotToken: IRouter = {
  path: '/forgot/:code',
  loader: React.lazy(() => import('./index')),
  exact: true,
  masterLayout: false,
};
