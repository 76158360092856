import React from 'react';

import { IRouter } from '@routers/interface';

export const routerResetForgotPass: IRouter = {
  path: '/reset-forgot-password',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'resetForgotPass.name', //translate here for breadcrumb and sidebar
};
